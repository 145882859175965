
@font-face {
  font-family: 'Neo Sans Arabic';
  font-display: swap;
  src: url('../fonts/NeoSansArabic-Light.woff2') format('woff2'),
      url('../fonts/NeoSansArabic-Light.woff') format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Neo Sans Arabic';
  font-display: swap;
  src: url('../fonts/NeoSansArabic.woff2') format('woff2'),
      url('../fonts/NeoSansArabic.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Neo Sans Arabic';
  font-display: swap;
  src: url('../fonts/NeoSansArabic-Medium.woff2') format('woff2'),
      url('../fonts/NeoSansArabic-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Neo Sans Arabic';
  font-display: swap;
  src: url('../fonts/NeoSansArabic-Bold.woff2') format('woff2'),
      url('../fonts/NeoSansArabic-Bold.woff') format('woff');
  font-weight: 700;
}



body {
  font-family: 'Neo Sans Arabic', sans-serif;
  
}
